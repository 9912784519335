body {
  font-size: 14px;

  .pagination {
    li {
      a {
        font-size: 13px;
      }
    }
  }

  td {
    .badge {
      font-weight: normal;
      border-radius: 3px;
      padding-left: 0.75em;
      padding-right: 0.75em;
    }
  }

  .pagination {
    .page-item {
      margin: 0 1px;

      .break-link {
        background: transparent;
        border: 0;
      }
    }
  }
}

$light-bg-1: rgba(255, 255, 255, 0.6);

body:not(.dark-mode) {
  .header {
    .navbar {
      background-color: $light-bg-1;
    }
  }

  .sidebar {
    background-color: $light-bg-1;
    border-radius: 0 8px 0 0;
  }

  hr {
    border-color: rgba(0, 0, 0, 0.4);
  }
}

.dark-mode {
  .react-select {
    color: #fff !important;

    &__input-container {
      color: #fff !important;
    }

    &__menu {
      background-color: #111111;
      color: #fff !important;

      .react-select__option {
        color: #fff !important;
        background-color: transparent !important;
      }
    }

    &__control {
      background-color: #111111;
      border-color: rgba(255, 255, 255, 0.085);
    }

    &__option {
      background: none !important;

      &:hover,
      &--is-selected {
        background: rgb(37, 33, 33) !important;
      }
    }

    &__single-value {
      color: #fff;
    }
  }
}

.tree {
  text-align: center;
  margin-top: 24px;

  .card .card-body {
    background: none;
  }

  ul {
    margin: 0;
    padding: 0;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;

    li {
      margin: 0;
      padding: 0;
      text-align: center;
      list-style-type: none;
      position: relative;
      padding: 20px 5px 0 5px;
      transition: all 0.5s;
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
      float: left;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 50%;
        border-top: 1px solid #ccc;
        width: 50%;
        height: 20px;
      }

      &::after {
        right: auto;
        left: 50%;
        border-left: 1px solid #ccc;
      }

      &:only-child::after,
      &:only-child::before {
        display: none;
      }

      &:only-child {
        padding-top: 0;
      }

      &:first-child::before,
      &:last-child::after {
        border: 0 none;
      }

      &:last-child::before {
        border-right: 1px solid #ccc;
        border-radius: 0 5px 0 0;
        -webkit-border-radius: 0 5px 0 0;
        -moz-border-radius: 0 5px 0 0;
      }

      &:first-child::after {
        border-radius: 5px 0 0 0;
        -webkit-border-radius: 5px 0 0 0;
        -moz-border-radius: 5px 0 0 0;
      }

      a,
      .user-tree {
        width: 168px;
        min-height: 188px;
        overflow: hidden;
        border: 1px solid #ccc;
        text-decoration: none;
        color: #666;
        font-family: arial, verdana, tahoma;
        display: inline-block;
        background-color: #fff;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        transition: all 0.5s;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        position: relative;
        z-index: 1;
      }

      .user-tree.user-tree:hover,
      .user-tree:hover+ul li .user-tree {
        background: #e9edf7;
        color: #000;
        border: 1px solid #111111;
      }

      .user-tree:hover+ul li::after,
      .user-tree:hover+ul li::before,
      .user-tree:hover+ul::before,
      .user-tree:hover+ul ul::before {
        border-color: #111111;
      }
    }
  }

  >ul>li {
    text-align: center;
  }

  ul ul {
    padding-top: 20px;
    position: relative;
    display: flex;

    &::before {
      content: '';
      position: absolute;
      top: -4px;
      left: 50%;
      border-left: 1px solid #ccc;
      width: 0;
      height: 24px;
      z-index: 0;
    }
  }
}

.sidebar-wrap {
  padding-top: 85px;
}

main.mainheight {
  margin-top: 85px;
}

@media only screen and (max-width: 600px) {
  .tree {
    li {
      .user-tree {
        width: 100px;
        height: 150px;

        .card-body {
          padding: 0;
          font-size: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .sidebar-wrap {
    padding-top: 58px;
  }

  main.mainheight {
    margin-top: 58px;
  }
}

.loading-data {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 2;
}

.btn {
  cursor: pointer;
}

.lbl-sm {
  transform: scale(0.75) translateX(-0.9rem);
  opacity: 0.65;
}

.row-of-tbl-selected {
  background-color: #c2e7ff;
}

.row-of-tbl,
.row-of-tbl-selected {
  .btn {
    opacity: 1;
    transition: all 0.3s;
  }

  &:hover {
    .btn {
      opacity: 1;
    }
  }
}

.tree-container {
  overflow: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.table-hover>tbody>tr:hover>* {
  color: inherit;
}

.react-select {
  &__indicator {
    padding-left: 0;
    padding-bottom: 6px !important;
    padding-top: 6px !important;
  }

  &__control {
    align-items: flex-start !important;
    min-height: unset !important;
  }
}

#selectUser {
  .react-select__placeholder {
    white-space: nowrap;
  }

  .react-select__indicators,
  .react-select__indicator {
    padding-left: 0 !important;
    padding-right: 2px !important;
  }
}

.badge-sm {
  font-weight: normal;
  border-radius: 3px;
  padding-left: 0.75em;
  padding-right: 0.75em;
}

.modal-90w {
  min-width: 96vw;
}

.modal-body {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.modal-fullscreen {
  .modal-body {
    max-height: none;
  }
}

.border-danger {
  border-color: #dc3545 !important;
}

.switch-dark-mode {
  .checkbox {
    opacity: 0;
    position: absolute;
  }

  .label {
    width: 28px;
    height: 16px;
    background-color: #333;
    display: flex;
    border-radius: 50px;
    align-items: center;
    justify-content: space-between;
    padding: 1px 5px;
    position: relative;
    transform: scale(1.2);
    border: solid 1px #ccc;
  }

  .ball {
    width: 10px;
    height: 10px;
    background-color: white;
    position: absolute;
    top: 2px;
    left: 1px;
    border-radius: 50%;
    transition: transform 0.2s linear;
  }

  .checkbox:checked+.label .ball {
    transform: translateX(14px);
  }

  .bi {
    font-size: 8px;
  }

  .checkbox:checked~.label {
    .bi-sun {
      opacity: 0;
    }
  }

  .checkbox:not(:checked)~.label {
    .bi-moon {
      opacity: 0;
    }
  }

  .bi-moon {
    color: pink;
  }

  .bi-sun,
  .bi-brightness-low-fill {
    color: yellow;
    font-size: 9px;
  }
}

.figure-img-detail:hover img {
  opacity: 0;
}

.img-zoom {
  img {
    max-width: none;
  }
}

.mycard-style-1 {
  background-color: #2b489a;
  color: #f7f8fa;
  border-radius: 8px !important;
  overflow: hidden;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: solid 3px transparent !important;

  &.selected {
    border: solid 3px #01b6f7 !important;
  }

  .card-header {
    background: linear-gradient(90deg, #252f72 2.43%, #3341a0 52.74%, #283693 100%);
    border-bottom-color: #f0b139 !important;
    border-bottom: solid 1px;
    padding-bottom: 8px !important;
    padding-left: 8px;
    padding-right: 8px;
    font-weight: normal;

    .title-gold {
      background: linear-gradient(93.61deg, #f0b139 -1.64%, #fefc8b 60.24%, #e1a635 110.44%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      font-size: 16px;
    }

    .title-blue {
      background: linear-gradient(270deg, #22d1ee 0%, #1fe3fd 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      font-size: 16px;
    }
  }

  .card-body {
    background-color: #2b489a !important;
    border: 0 !important;
    padding: 0;
    font-size: 11px;
  }

  .text-secondary {
    color: rgba(255, 255, 255, 0.6) !important;
    font-weight: normal;
  }

  .card-footer {
    background-color: #252f72 !important;
    border-top-color: #f0b139 !important;
    border-top: solid 1px;
    margin-top: 0 !important;
  }

  .row-color-1 {
    background-color: #2b489a !important;
    padding: 6px 8px;
  }

  .row-color-2 {
    background-color: #252f72 !important;
    padding: 6px 8px;
  }

  .line {
    padding: 0;
    margin: 0;
    height: 1px;
    width: 100%;
    background: #f0b139;
  }

  .icon-active {
    position: absolute;
    top: 6px;
    padding: 2px 8px;
    padding-top: 1px;
    background-color: #e1a635;
    border-radius: 14px;
    right: 8px;
    font-size: 10px;
    font-weight: bold;
    background: linear-gradient(90deg, #c18d2b 4.13%, #fefc8b 100%);
    border: 1px solid #fefc8b;
    color: #283693;
    font-size: 20px;
    padding: 0;
    width: 24px;
    height: 24px;
    line-height: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    &.style-2 {
      background: linear-gradient(90deg, #00a1ff 4.13%, #1fe3fd 100%);
      border: 1px solid #1fe3fd;
    }
  }
}

.checkboxMonthly {
  position: relative;

  .title-blue {
    padding-left: 16px;
  }

  input {
    position: absolute;
    z-index: 1;
    left: 12px;
    top: 12px;

    &:checked+.mycard-style-1 {
      border: solid 3px #01b6f7 !important;
    }
  }
}

.swal-text {
  text-align: center;
}

.swal-footer {
  text-align: center !important;

  .swal-button {
    padding-left: 36px;
    padding-right: 36px;
  }
}

.swal-text {
  text-align: center;
}

.user-annual {
  position: relative;

  .button-remove-user {
    position: absolute;
    top: -10px;
    right: -6px;
    background-color: #535252;
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 20px;
    cursor: pointer;

    &:hover {
      background-color: red;
    }
  }
}

.button-remove-user-all {
  color: #00a1ff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: underline;
  padding-left: 12px;
  cursor: pointer;

  &:hover {
    color: #2b489a;
  }
}

.span-ower-username-ticket {
  &:first-child {
    margin-left: 6px;
  }

  &+.span-ower-username-ticket {
    &::before {
      content: ';';
      margin-right: 4px;
      margin-left: 1px;
    }
  }
}

#dropdown-user {
  background-color: transparent !important;
  border: 0;
  box-shadow: none;

  &::after {
    display: none;
  }
}

.simple-views {
  .table> :not(caption)>*>* {
    --WinDOORS-border-color: #ffffff00;
  }

  &__rows {
    vertical-align: middle;
    height: 68px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    border-radius: 12px;
    border: 2px solid #ffffff00;

    button {
      color: #0d96d4;
    }

    &:nth-child(2n) {
      background: #f8f8f8;
      transition: all 0.3 ease;
    }

    &:hover {
      transition: all 0.3 ease;
      border-color: #0D96D4
    }
  }

  &__scroll {
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #F8F8F8;
    }

    &::-webkit-scrollbar-thumb {
      background: #1286DC;
      border-radius: 5px;
    }
  }

  &__details {
    min-width: 750px;
  }
}

.bg-table__header {
  background-color: #0d96d4 !important;
}

.border-radius-top_12px {
  --bs-card-inner-border-radius: 12px;
}

.rt-input-input {
  position: relative;

}

.rt-input-input::after {
  content: '%';
  font-family: sans-serif;
  width: 1em;
  height: 1em;
  position: absolute;
  top: 50%;
  right: 5px;

  transform: translateY(-50%);
}

.coverimg {
  img {
    height: 100%;
  }
}

.img-err {
  min-width: 100vw;
  min-height: 100vh;
  object-fit: cover;
}

.modal-user {
  .btn-close {
    display: none;
  }
}

.modal-user {
  color: #111;

  .modal-content {
    position: relative;

    &::before {
      content: '';
      display: inline-block;
      left: -1px;
      top: -1px;
      right: -1px;
      bottom: -1px;
      position: absolute;
      z-index: -2;
      background: var(--gradient,
          linear-gradient(90deg,
            #c18d2b 0%,
            #fefc8b 21.88%,
            #c18d2b 51.04%,
            #fefc8b 70.83%,
            #c18d2b 97.92%));
      padding: 1px;
      border-radius: 16px;
    }

    &::after {
      content: '';
      display: inline-block;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      z-index: -1;
      border-radius: 16px;
      background: #fff;
    }

    padding: 50px 50px;
    border-radius: 16px;
    color: #111 !important;
    background-color: #fff !important;

    .modal-header {
      border: 0;
      text-align: center;
      justify-content: center;
    }

    .modal-footer {
      border: 0;
    }
  }

  &__title {
    position: relative;
    text-align: center;
    font-weight: 700;
    font-size: 23px;
    text-align: center;
  }

  .form-group {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    label {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 8px;

    }

    .icon {
      position: absolute;
      right: 10px;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #666;

      ~.form-control {
        border-right: 0 !important;
        padding-right: 24px;
      }
    }

    .form-control {
      height: 40px;
      border: 1px #c18d2b solid;
      border-radius: 6px;
    }
  }

  &__text-highlight {
    color: #00a1ff;
    font-weight: 400;
    font-size: 14px;
  }

  .btn-close {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #666e80;
    font-size: 16px;
  }

  .btn-primary {
    background: #2c3eba;
    --bs-btn-color: #fff;
    --bs-btn-bg: #2c3eba;
    --bs-btn-border-color: #2c3eba;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #2c3eba;
    --bs-btn-hover-border-color: #2c3eba;
    --bs-btn-focus-shadow-rgb: 39, 118, 203;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #2c3eba;
    --bs-btn-active-border-color: #2c3eba;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #2c3eba;
    --bs-btn-disabled-border-color: #2c3eba;
    border-radius: 4px;
  }

  .btn-style-blue {
    border: 0 !important;
    background-color: #2c3eba;
    border-radius: 6px;
    background: url('../../../public/assets/img/buttn-login-bg.png') !important;
    background-size: 100% 100% !important;
    font-size: 1rem;
    color: white !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    font-size: 14px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

@media screen and (max-width:500px) {
  .modal-user {
    &__wrapper {
      .modal-content {
        padding: 20px 22px;
      }
    }
  }
}

.myfrm {
  label {
    font-weight: 600;
  }
}

.btn-select-type {
  width: 100%;
  height: 100px;
  background-color: #ebf9ff;
  color: #000;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  border: 1px solid #7e838b !important;
  border-bottom-width: 1px !important;
}

.payout-wallet-item {
  background-color: #f0f0f0;
  width: 100%;
  border-radius: 8px;
  padding: 20px 20px;
  position: relative;

  .iconbutton {
    background-color: #ffffff;
    height: 28px;
    width: 28px;
    align-self: flex-start;
    right: 0;
    top: -12px;
    border: 1px solid red !important;
    color: red;
    position: absolute;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.error-msg {
  margin-top: 6px;
  color: red;
}
.image-hover-block{
  overflow: hidden;
  border-radius:15px;
  height: 255px;
  display: flex;
  justify-content: center;
  align-items: center;

}
.image-hover-block > img{
  width: 100%;
}
.btn-edit{
  border: 1px solid #0150a5;
  border-radius:8px;
  padding: 3px 10px; 
  font-size: 15px;
  background-color: #0150a5;
  color: white;
}
.modal .modal-dialog .modal-content {
  background-color: rgba(var(--WinDOORS-theme-bg-rgb), 1);
}
.image-block{
  width: 100%;
  height: 300px;
  border: 1px solid rgb(208, 208, 208);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-block > img{
  display: flex;
  justify-content: center;
  height: 100%;
  border-radius:10px;
}
.image-upload{
  width: 100px;
  height: 100px;

}
input[type="file"]::file-selector-button {
  border: 2px solid #015EC2;
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  background-color: #015EC2;
  transition: 1s;
  color: white;
  padding: .5rem 1rem;
}
.trash-can {
  color: red;
}

.bi.bi-trash-fill:before {
  font-size: 18px; 
  vertical-align: middle; 
}
.block-image-list{
  position: relative;
}
.trash-can{
  position: absolute;
  right: 9px;
  top: 2%;
  border: 1px solid #b0b0b0;
  border-radius: 100%;
  padding: 0.2rem;
  z-index: 100;
  background: #e3dfdf;
  cursor: pointer;
}
.progress {
  height: 15px;
  margin-top: 10px;
  width: 500px;
}

.progress-bar {
  background: linear-gradient(to right, #00bfff, #b19cd9);
  background-size: 200% 100%;
  transition: width 2s ease-in-out;
  animation: gradientAnimation 4s ease-in-out infinite;
  width: 0%;
}
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.group-item {
  padding: 2.5rem 0.5rem;
  border-bottom: 1.5px solid #e0d7d7;
}

.group-item:last-child {
  border-bottom: none;
}
.form-check{
  display: flex;
  justify-content: center;
  align-items: center;
}